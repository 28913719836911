import React, { useState, useEffect } from "react";
import "./../../../css/sidebar.css";
import "./../../../css/style.css";
import "./../../../css/responsive.css";
import customers from "./../../../assets/Images/customer-icon.svg";
import customersWhiteIcon from "./../../../assets/Images/customerWhiteIcon.svg";
import workspace from "./../../../assets/Images/workspace.svg";
import workspaceBlueIcon from "./../../../assets/Images/workspace_blue.svg";
import reports from "./../../../assets/Images/reports.svg";
import chats from "./../../../assets/Images/chats.svg";
import settings from "./../../../assets/Images/setting.svg";
import settingsBlue from "./../../../assets/Images/setting_blue.svg";
import staff from "./../../../assets/Images/staff.svg";
import staffWhiteIcons from "./../../../assets/Images/staffWhiteIcon.svg";
import dashboard from "./../../../assets/Images/dashboard.svg";
import dashboardBlack from "./../../../assets/Images/dashboardIconBlack.svg";
import { Link } from "react-router-dom";
import togglerRight from "./../../../assets/Images/toggler-menu.svg";
import toggleLeft from "./../../../assets/Images/workspace-toggle.svg";
import {
	HOIDAY_LIST_PAGE,
	ADD_HOLIDAY_PAGE,
	EDIT_HOLIDAY_PAGE,
	WORK_SHIFTS_LISTS_PAGE,
	WORK_SHIFT_EDIT_PAGE,
	WORK_SHIFT_ADD_PAGE,
	// EDIT_TYPES_LISTS_PAGE,
	VIEW_BRANCHE_PAGE,
	EDIT_BRANCHE_PAGE,
	ADD_BRANCHE_PAGE,
	VIEW_ENTERPRISE_FIELD,
	EDIT_ENTERPRISE_FIELD,
	ADD_ENTERPRISE_FIELD,
	PREVIEW_ENTERPRISE_FIELD,
	VIEW_ENTERPRISE_REQUESTS,
	VIEW_ENTERPRISE_REQUEST_DETAILS,
	VIEW_COORDINATOR_WORKSPACE,
	VIEW_EDITOR_WORKSPACE,
	DASHBOARD,
	VIEW_CUSTOMER_PAGE,
	CUSTOMER_ADD_PAGE,
	EDIT_CUSTOMER_PAGE,
	CUSTOMERS_DETAILS,
	STAFF_PAGE,
	STAFF_VIEW_PAGE,
	STAFF_ADD_PAGE,
	EDIT_STAFF_USER_PAGE,
	STAFF_DETAILS_PAGE,
	EDIT_TYPES_LISTS_PAGE,
	EDIT_TYPE_EDIT_PAGE,
	EDIT_TYPE_ADD_PAGE,
	TERMS_AND_CONDITION_VIEW_PAGE,
} from "../../../config/routes";
/**
 * Sidebar component that renders a navigation menu.
 * @returns {JSX.Element} The rendered sidebar component.
 */
const Sidebar = ({ isMenuOpen, setMenuOpen }) => {
	const [active, setActive] = useState("");
	const [activeTab, setActiveTab] = useState(""); // State to manage active tab
	const [activeSubTab, setActiveSubTab] = useState("");
	const [activeLink, setactiveLink] = useState("");
	const [expandedItems, setExpandedItems] = useState([]);

	const currentRoute = window.location.pathname;

	const toggleMenu = () => {
		// Toggle the menu state
		setMenuOpen(!isMenuOpen);
	};
	useEffect(() => {
		const routeTabMapping = {
			[DASHBOARD]: "dashboard",
			[VIEW_CUSTOMER_PAGE]: "customers",
			[CUSTOMER_ADD_PAGE]: "customers",
			[EDIT_CUSTOMER_PAGE]: "customers",
			[CUSTOMERS_DETAILS]: "customers",
			[STAFF_PAGE]: "staff",
			[STAFF_VIEW_PAGE]: "staff",
			[STAFF_ADD_PAGE]: "staff",
			[EDIT_STAFF_USER_PAGE]: "staff",
			[STAFF_DETAILS_PAGE]: "staff",
			[EDIT_TYPES_LISTS_PAGE]: "settings",
			[EDIT_TYPE_EDIT_PAGE]: "settings",
			[EDIT_TYPE_ADD_PAGE]: "settings",
			[TERMS_AND_CONDITION_VIEW_PAGE]: "settings",
			[VIEW_ENTERPRISE_FIELD]: "settings",
			[EDIT_ENTERPRISE_FIELD]: "settings",
			[ADD_ENTERPRISE_FIELD]: "settings",
			[PREVIEW_ENTERPRISE_FIELD]: "settings",
			[VIEW_ENTERPRISE_REQUESTS]: "settings",
			[VIEW_ENTERPRISE_REQUEST_DETAILS]: "settings",
			[VIEW_BRANCHE_PAGE]: "settings",
			[EDIT_BRANCHE_PAGE]: "settings",
			[ADD_BRANCHE_PAGE]: "settings",
			[HOIDAY_LIST_PAGE]: "settings",
			[ADD_HOLIDAY_PAGE]: "settings",
			[EDIT_HOLIDAY_PAGE]: "settings",
			[WORK_SHIFTS_LISTS_PAGE]: "settings",
			[WORK_SHIFT_EDIT_PAGE]: "settings",
			[WORK_SHIFT_ADD_PAGE]: "settings",
			[VIEW_COORDINATOR_WORKSPACE]: "workspace",
			[VIEW_EDITOR_WORKSPACE]: "workspace",
			["/qc-workspace"]: "workspace",
		};

		const findActiveTab = () => {
			for (const route of Object.keys(routeTabMapping)) {
				if (currentRoute.includes(route)) {
					return routeTabMapping[route];
				}
			}
			return "dashboard"; // Define a default tab if no match is found
		};
		const dropDowncloseonOtherTabClick = () => {
			for (const route of Object.keys(routeTabMapping)) {
				if (
					currentRoute.includes(DASHBOARD) ||
					currentRoute.includes(VIEW_CUSTOMER_PAGE) ||
					currentRoute.includes(STAFF_PAGE)
				) {
					document
						.querySelectorAll("#sidebar .nav .nav-item")
						.forEach((element) => {
							const ths = element;
							if (ths.querySelectorAll(".collapse").length > 0) {
								setTimeout(() => {
									ths
										.querySelector(".nav-links")
										.setAttribute("aria-expanded", "false");
									ths.querySelector(".collapse").classList.remove("show");
								}, 10);
							}
						});
					setActiveSubTab("");
					setActive("");
				}
			}
		};
		dropDowncloseonOtherTabClick();

		setActiveTab(findActiveTab());
	}, [currentRoute]);

	useEffect(() => {
		// Check current route is includes settings

		if (currentRoute.includes("/coordinator-workspace")) {
			setActive("tab");
			setActiveSubTab("");
		} else if (currentRoute.includes("/editor-workspace")) {
			setActive("tab1");
			setActiveSubTab("");
		} else if (currentRoute.includes("/qc-workspace")) {
			setActive("tab2");
			setActiveSubTab("");
		} else if (currentRoute.includes("/settings-view-branch")) {
			setActive("");
			setActiveSubTab("tabSub1");
		} else if (currentRoute.includes(WORK_SHIFTS_LISTS_PAGE)) {
			setActive("");
			setActiveSubTab("tabSub2");
		} else if (currentRoute.includes(HOIDAY_LIST_PAGE)) {
			setActive("");
			setActiveSubTab("tabSub3");
		} else if (currentRoute.includes(EDIT_TYPES_LISTS_PAGE)) {
			setActive("");
			setActiveSubTab("tabSub4");
		} else if (currentRoute.includes(TERMS_AND_CONDITION_VIEW_PAGE)) {
			setActive("");
			setActiveSubTab("tabSub5");
		} else if (currentRoute.includes(VIEW_ENTERPRISE_FIELD)) {
			setActive("");
			setActiveSubTab("tabSub6");
		}
	}, [currentRoute]);
	// Handle to remove the current collapsed effect of main menu from the sidebar

	useEffect(() => {
		document.querySelectorAll("#sidebar .nav .nav-item").forEach((element) => {
			const ths = element;
			if (ths.querySelectorAll(".collapse").length > 0) {
				setTimeout(() => {
					if (!expandedItems.includes(ths)) {
						ths
							.querySelector(".nav-links")
							.setAttribute("aria-expanded", "false");
						ths.querySelector(".collapse").classList.remove("show");
					}
				}, 10);
			}
		});
	}, [activeLink, expandedItems]);

	const toggleSubMenu = (element) => {
		if (expandedItems.includes(element)) {
			setExpandedItems(expandedItems.filter((item) => item !== element));
		} else {
			setExpandedItems([...expandedItems, element]);
		}
	};

	// Handle to remove the current collapsed effect of main menu from the sidebar if the sidebar width collapse

	useEffect(() => {
		if (!isMenuOpen) {
			document
				.querySelectorAll("#sidebar .nav .nav-item")
				.forEach((element) => {
					const ths = element;
					if (ths.querySelectorAll(".collapse").length > 0) {
						setTimeout(() => {
							ths
								.querySelector(".nav-links")
								.setAttribute("aria-expanded", "false");
							ths.querySelector(".collapse").classList.remove("show");
						}, 10);
					}
				});
		}
	}, [isMenuOpen]);

	return (
		<>
			<nav
				className={`${
					isMenuOpen ? `sidebar` : `sidebar sidebar--close`
				} sidebar-offcanvas`}
				id="sidebar"
			>
				<ul className="nav">
					<li
						className={`nav-item ${activeTab === "dashboard" ? "active" : ""}`}
					>
						<Link
							className="nav-links"
							to={DASHBOARD}
							onClick={() => {
								setActive("tab1");
							}}
						>
							<img
								// src={dashboard}
								src={
									activeTab === "dashboard" // If activeTab is "dashboard"
										? dashboard // Render the dashboard variable
										: dashboardBlack
								}
								className="img-fluid menu-icon"
								alt="dashboard"
							/>
							<span
								className={`${isMenuOpen ? `menu-title` : `display--none`}`}
							>
								Dashboard
							</span>
						</Link>
					</li>
					<li
						className={`nav-item ${activeTab === "customers" ? "active" : ""}`}
					>
						<Link
							className="nav-links"
							to={VIEW_CUSTOMER_PAGE}
							onClick={() => {
								setActive("tab2");
							}}
						>
							<img
								src={
									activeTab === "customers" // If activeTab is "customers"
										? customersWhiteIcon // Render the dashboard variable
										: customers
								}
								className="img-fluid menu-icon"
								alt="customers"
							/>
							<span
								className={`${isMenuOpen ? `menu-title` : `display--none`}`}
							>
								Customers
							</span>
						</Link>
					</li>
					<li
						className={`nav-item ${
							activeTab === "workspace" ? "bg-highlighter " : ""
						}`}
					>
						<Link
							className="nav-links"
							data-bs-toggle="collapse"
							to="#ui-basic"
							aria-expanded="false"
							aria-controls="ui-basic"
							// onClick={() => {
							// 	setactiveLink("Workspace");
							// }}
							onClick={() => toggleSubMenu("Workspace")}
						>
							<img
								src={activeTab === "workspace" ? workspaceBlueIcon : workspace}
								className="img-fluid menu-icon"
								alt="workspace"
							/>
							<div className={`${isMenuOpen ? `menu-title` : `display--none`}`}>
								<span
									className={
										activeTab === "workspace"
											? "menu-title sub-menu--blue"
											: "menu-title"
									}
								>
									Workspace
								</span>
							</div>
							{isMenuOpen && (
								<i
									className={
										(activeTab === "workspace"
											? "fa  menu-arrow--highlight"
											: "fa menu-arrow") +
										(active === "tab3" ? " fa-angle-up" : " fa-angle-down")
									}
								></i>
							)}
							<i className="mdi mdi-crosshairs-gps menu-icon"></i>
						</Link>
						<div className="collapse" id="ui-basic">
							<ul
								className="nav flex-column sub-menu"
								style={{
									background: " #fff",
								}}
							>
								<li className="nav-item">
									{" "}
									<Link
										className={`nav-links ${active === "tab" ? "active" : ""}`}
										to={VIEW_COORDINATOR_WORKSPACE}
										// onClick={() => {
										// 	setActive("tab");
										// 	// setActiveSubTab("");
										// }}
									>
										Coordinator Workspace
									</Link>
								</li>
								<li className="nav-item">
									{" "}
									<Link
										className={`nav-links ${active === "tab1" ? "active" : ""}`}
										to={VIEW_EDITOR_WORKSPACE}
										// onClick={() => {
										// 	setActive("tab1");
										// 	// setActiveSubTab("");
										// }}
									>
										Editor Workspace
									</Link>
								</li>
								<li className="nav-item">
									{" "}
									<Link
										className={`nav-links ${active === "tab2" ? "active" : ""}`}
										to="/qc-workspace"
										// onClick={() => {
										// 	setActive("tab2");
										// 	// setActiveSubTab("");
										// }}
									>
										QC Workspace
									</Link>
								</li>
							</ul>
						</div>
					</li>
					{/* <li className={`nav-item ${active === "tab4" ? "active" : ""}`}>
						<Link
							className="nav-links"
							data-bs-toggle="collapse"
							to="#general-pages"
							aria-expanded="false"
							aria-controls="general-pages"
							onClick={() => {
								setactiveLink("Reports");
							}}
						>
							<img
								src={reports}
								className="img-fluid menu-icon"
								alt="reports"
							/>
							<span
								className={`${isMenuOpen ? `menu-title` : `display--none`}`}
							>
								Reports
							</span>
							<i className="fa fa-angle-down menu-arrow"></i>
						</Link>
						<div className="collapse" id="general-pages">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									{" "}
									<Link
										className="nav-links"
										to="/customers-monetary-comparison"
										onClick={() => {
											setActive("tab4");
										}}
									>
										{" "}
										Customers Monetary Comparison{" "}
									</Link>
								</li>
								<li className="nav-item">
									{" "}
									<Link
										className="nav-links"
										to="/customers-performance-comparison"
										onClick={() => {
											setActive("tab4");
										}}
									>
										{" "}
										Customers Performance Comparison{" "}
									</Link>
								</li>
								<li className="nav-item">
									{" "}
									<Link
										className="nav-links"
										to="/edit-type-performance"
										onClick={() => setActive("tab4")}
									>
										{" "}
										Edit Type Performance{" "}
									</Link>
								</li>
								<li className="nav-item">
									{" "}
									<Link
										className="nav-links"
										to="/staff-performance-comparison"
										onClick={() => setActive("tab4")}
									>
										{" "}
										Staff Performance Comparison{" "}
									</Link>
								</li>
							</ul>
						</div>
					</li> */}

					<li className={`nav-item ${activeTab === "staff" ? "active" : ""}`}>
						<Link
							className="nav-links"
							to="/staff"
							onClick={() => {
								setActive("tab5");
							}}
						>
							<img
								// src={staff}
								src={
									activeTab === "staff" // If activeTab is "staff"
										? staffWhiteIcons // Render the dashboard variable
										: staff
								}
								className="img-fluid menu-icon"
								alt="staff"
							/>
							<span
								className={`${isMenuOpen ? `menu-title` : `display--none`}`}
							>
								Staffs
							</span>
						</Link>
					</li>
					{/* <li className={`nav-item ${active === "tab6" ? "active" : ""}`}>
						<Link
							className="nav-links"
							to="/chats"
							onClick={() => {
								setActive("tab6");
							}}
						>
							<img src={chats} className="img-fluid menu-icon" alt="chats" />
							<span
								className={`${isMenuOpen ? `menu-title` : `display--none`}`}
							>
								Chats
							</span>
						</Link>
					</li> */}
					<li
						// className={`nav-item `}
						className={`nav-item ${
							activeTab === "settings" ? "bg-highlighter " : ""
						}`}
					>
						<Link
							className={`nav-links `}
							// className={`nav-links p-b--0${
							// 	activeTab === "settings" ? "bg-highlighter " : ""
							// }`}
							data-bs-toggle="collapse"
							to="#ui-basic-setting"
							aria-expanded="false"
							aria-controls="ui-basic-setting"
							// onClick={() => {
							// 	setactiveLink("Settings");
							// 	setActive("tab7");
							// }}
							onClick={() => toggleSubMenu("Settings")}
						>
							<img
								src={activeTab === "settings" ? settingsBlue : settings}
								className="img-fluid menu-icon"
								alt="workspace"
							/>
							<div
								className={`${
									isMenuOpen ? `menu-title` : `display--none`
								} sub-menu`}
							>
								<span
									className={
										activeTab === "settings"
											? "menu-title sub-menu--blue"
											: "menu-title"
									}
								>
									Settings
								</span>
							</div>
							{isMenuOpen && (
								<i
									className={
										(activeTab === "settings"
											? "fa  menu-arrow--highlight"
											: "fa menu-arrow") +
										(active === "tab3" ? " fa-angle-up" : " fa-angle-down")
									}
								></i>
							)}
							<i className="mdi mdi-crosshairs-gps menu-icon"></i>
						</Link>
						<div className="collapse" id="ui-basic-setting">
							<ul
								className="nav flex-column sub-menu "
								style={{
									height: "16em",
									overflow: "scroll",
									background: " #fff",
								}}
							>
								<li className={`nav-item `}>
									{" "}
									<Link
										className={`nav-links ${
											activeSubTab === "tabSub1" ? "active" : ""
										}`}
										to={VIEW_BRANCHE_PAGE}
										// onClick={() => {
										// 	// setActive("tab7");
										// 	setActiveSubTab("tabSub1");
										// }}
									>
										Branches
									</Link>
									<Link
										className={`nav-links ${
											activeSubTab === "tabSub2" ? "active" : ""
										}`}
										to={WORK_SHIFTS_LISTS_PAGE}
										// onClick={() => {
										// 	// setActive("tab7");
										// 	setActiveSubTab("tabSub2");
										// }}
									>
										Work Shifts
									</Link>
									<Link
										className={`nav-links ${
											activeSubTab === "tabSub3" ? "active" : ""
										}`}
										to={HOIDAY_LIST_PAGE}
										// onClick={() => {
										// 	// setActive("tab7");
										// 	setActiveSubTab("tabSub3");
										// }}
									>
										Holidays
									</Link>
									<Link
										className={`nav-links ${
											activeSubTab === "tabSub4" ? "active" : ""
										}`}
										to={EDIT_TYPES_LISTS_PAGE}
										// onClick={() => {
										// 	// setActive("tab7");
										// 	setActiveSubTab("tabSub4");
										// }}
									>
										Edit Types
									</Link>
									<Link
										className={`nav-links ${
											activeSubTab === "tabSub5" ? "active" : ""
										}`}
										to={TERMS_AND_CONDITION_VIEW_PAGE}
										// onClick={() => {
										// 	// setActive("tab7");
										// 	setActiveSubTab("tabSub5");
										// }}
									>
										Terms & Condition
									</Link>
									<Link
										className={`nav-links ${
											activeSubTab === "tabSub6" ? "active" : ""
										}`}
										to={VIEW_ENTERPRISE_FIELD}
										// onClick={() => {
										// 	// setActive("tab7");
										// 	setActiveSubTab("tabSub6");
										// }}
									>
										Enterprise Signup Questions
									</Link>
									<Link
										className={`nav-links ${
											activeSubTab === "tabSub6" ? "active" : ""
										}`}
										to={VIEW_ENTERPRISE_REQUESTS}
										// onClick={() => {
										// 	// setActive("tab7");
										// 	setActiveSubTab("tabSub6");
										// }}
									>
										Nike Enterprise Signup Form Answers
									</Link>
								</li>
							</ul>
						</div>
					</li>

					{/* <li className="nav-item">
            <Link
              className={`nav-links `}
              data-bs-toggle="collapse"
              to="#become-enterprise-settings"
              aria-expanded="false"
              aria-controls="become-enterprise-settings"
              onClick={() => setActive("tab8")}
            >
              <img
                src={settings}
                className="img-fluid menu-icon"
                alt="workspace"
              />
              <span className="menu-title sub-menu">Enterprise Settings</span>
              <i className="fa fa-angle-down menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </Link>
            <div className="collapse" id="become-enterprise-settings">
              <ul className="nav flex-column sub-menu">
                <li className={`nav-item `}>
                  {" "}
                  <Link
                    className={`nav-links ${
                      activeSubTab === "tabSub1" ? "active" : ""
                    }`}
                    to={VIEW_ENTERPRISE_FIELD}
                    onClick={() => {
                      setActive("tab8");
                      setActiveSubTab("tabSub1");
                    }}
                  >
                    Enterprise Fields
                  </Link>
                  <Link
                    className={`nav-links ${
                      activeSubTab === "tabSub2" ? "active" : ""
                    }`}
                    to={"#"}
                    onClick={() => {
                      setActive("tab8");
                      setActiveSubTab("tabSub2");
                    }}
                  >
                    List Enterprise Requests
                  </Link>
                </li>
              </ul>
            </div>
          </li> */}
				</ul>
			</nav>
			<div
				className={`${isMenuOpen ? `togler-menu` : `togler-menu--close`}`}
				onClick={() => toggleMenu()}
			>
				<img
					src={isMenuOpen ? togglerRight : toggleLeft}
					className="img-fluid"
					alt="mindreach images"
				/>
			</div>
		</>
	);
};

export default Sidebar;
